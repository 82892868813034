










import {Component, Prop, Vue} from "vue-property-decorator";
import PanelScreen from "@/models/PanelScreen";
import PanelScreenService from "@/services/PanelScreenService";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";

@Component
export default class PanelScreenTab extends Vue {
    lang: any = getModule(LangModule).lang
    @Prop() readonly userId!: number
    panelScreens: PanelScreen[] = []
    loading: boolean = false
    headers = [
        { text: this.lang.path, value: "route", width: "200px" },
        { text: this.lang.pathName, value: "title", width: "200px" }
    ]


    created() {
        PanelScreenService.getPanelScreenByUserId(this, this.userId, this.panelScreens)
    }
}
