


























import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import UserView from "@/views/UserView.vue";
import User from "@/models/User";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import UserService from "@/services/UserService";

@Component
export default class UserTab extends Vue {
	@Prop() readonly userView!: UserView
	@Prop() readonly user!: User
	@Ref() readonly form!: HTMLFormElement
	lang: any = getModule(LangModule).lang

	update() {
		UserService.patchUser(this, this.user)
	}

}
